import $ from 'jquery';
import cx from 'classnames';
import Portal from '../../../../misc/Portal';
import React from 'react';
import ShareModal from '../../../../share/ShareModal';
import update from 'immutability-helper';
import TagEditorContainer from '../../../../tags/TagEditorContainer';
import TagsList from '../../../../tags/TagsList';
import FeedModalSidebarCommentsArea from './FeedModalSidebarCommentsArea.jsx';

import './FeedModalSidebar.css';

class FeedModalSidebar extends React.Component {
  state = {
    editingDescription: false,
    editingTags: false,
    newDescription: '',
    sharing: false,
    tags: null,
  };

  componentDidMount() {
    if (this.props.tagsUrl) {
      $.getJSON(this.props.tagsUrl).done(data => {
        this.setState({tags: data});
      });
    } else {
      this.setState({tags: []});
    }
  }

  _closeSharing = () => {
    this.setState({sharing: false});
  }

  _descriptionUpdated = (event) => {
    const {activityUrl, cmd, contextActions} = this.props;
    if (this.state.newDescription != this.props.description) {
      cmd.updateDescription(contextActions.rename.href, activityUrl, this.state.newDescription);
    }
    this.setState({
      editingDescription: false,
    });
  };

  _descriptionChanged = (event) => {
    this.setState({newDescription: event.target.value});
  };

  _editDescription = () => {
    this.setState({
      editingDescription: true,
      newDescription: this.props.description,
    }, () => {
      this._descriptionInput.focus();
    });
  };

  _editTags = () => {
    if (!this.state.tags || !this.state.tags.editable) {
      return;
    }
    this.setState({editingTags: true});
  };

  _likeToggle = () => {
    if (!this.props.activityUrl) {
      return;
    }
    this.props.onLike(this.props.activityUrl);
  };

  _removeTag = (tag) => {
    if (!this.state.tags || !tag.removable) {
      return;
    }
    if (!confirm('Are you sure?')) {
      return;
    }
    $.post(this.props.tagsUrl, {_method: 'DELETE', user_id: tag.user_id, child_id: tag.child_id, team_member_id: tag.team_member_id}).then(data => {
      const i = this.state.tags.tags.indexOf(tag);
      if (i < 0) {
        return;
      }
      this.setState(update(this.state, {
        tags: {
          tags: {
            $splice: [[i, 1]],
          },
        },
      }));
    });
  };

  _share = () => {
    this.setState({sharing: true});
  }

  _showcaseToggle = () => {
    const {activityUrl, contextActions, onShowcase} = this.props;
    if (!onShowcase) {
      return;
    }
    onShowcase(contextActions.showcase.href, activityUrl);
  };

  render() {
    const {author, cmd, comments, contextActions, destination, liked, likes, recipient, showcased, timestamp, views} = this.props;
    const editableTags = this.state.tags && this.state.tags.editable;
    let tagsArea = null;
    if (!this.state.tags) {
      tagsArea = (
        <div className="loading-gif"></div>
      );
    } else if (this.state.editingTags) {
      tagsArea = (
        <TagEditorContainer {...this.state.tags} />
      );
    } else {
      tagsArea = (
        <TagsList data={this.state.tags} onRemove={this._removeTag} />
      );
    }
    let sharing = null;
    if (this.state.sharing) {
      sharing = (
        <Portal>
          <ShareModal url={this.props.activityUrl} onCloseRequested={this._closeSharing} />
        </Portal>
      );
    }
    return (
      <div className="FeedModalSidebar">
        <header className="FeedModalSidebarAttribution">
          <div className="d-flex">
            <a className="FeedModalSidebarAttribution-avatar" href={author._links.self.href}>
              <img src={author._links.profile_picture.href} className="Post-profileImg" />
            </a>
            <div className="FeedModalSidebarAttributionDetails col">
              <div className="d-flex justify-content-between">
                <a href={author._links.self.href} className="postby-name">
                  {author.display_name}
                </a>
                <div className="Views-media">
                  <i className="fa fa-eye"></i> {views}
                </div>
              </div>
              <div className="Timestamp">
                {timestamp}
              </div>
            </div>
          </div>
          {
            recipient ? (
              <div className="Photo-info">
                {
                  recipient.href ? (
                    <a href={recipient.href}>{recipient.title}</a>
                  ): (
                    <span>{recipient.title}</span>
                  )
                }
              </div>
            ): null
          }
          {
            destination ? (
              <div className="Photo-info">
                <a href={destination.href}>{destination.title}</a>
              </div>
            ): null
          }
        </header>
        {
          !this.state.editingDescription && (this.props.description || (contextActions && contextActions.rename)) ? (
            <p className="description text-preline">
              {this.props.description} {contextActions && contextActions.rename ? (<a onClick={this._editDescription} className="fa fa-pencil" />) : null}
            </p>
          ): null
        }
        <div className={cx({hide: !this.state.editingDescription})}>
          <textarea ref={n => this._descriptionInput = n} className="form-control my-3" maxLength="1024" value={this.state.newDescription} onChange={this._descriptionChanged}></textarea>
          <div className="text-end">
            <a className="btn u-layoutBtnOutlineColor" onClick={this._descriptionUpdated}>Done</a>
          </div>
        </div>
        <div className="FeedModalSidebarActions">
          {tagsArea}
          {
            contextActions ? (
              <div className="FeedModalSidebarActions-buttons">
                {
                  editableTags && !this.state.editingTags ? (
                    <div className="toleft">
                      <a onClick={this._editTags}>
                        <span className="fa fa-tags" /> Tag
                      </a>
                    </div>
                  ): null
                }
                {
                  contextActions.donate ? (
                    <div className="toleft">
                      <a className="btn btn-highlight btn-xs" href={contextActions.donate.href}>
                        <span className="fa fa-dollar" /> Sponsor
                      </a>
                    </div>
                  ): null
                }
                {
                  contextActions.like ? (
                    <span onClick={this._likeToggle} className={`FeedModalSidebarLikeWrapper${liked ? ' FeedModalSidebarLikeButton--liked' : ''}`} >
                      {/* <span className={cx('fa', 'fa-arrow-up', 'FeedModalSidebarLikeButton', {'FeedModalSidebarLikeButton--liked': liked})}></span> Likes ({likes}) */}
                      <span className={`fa fa-arrow-up FeedModalSidebarLikeButton${liked ? ' FeedModalSidebarLikeButton--liked' : ''}`}></span> Likes ({likes})
                    </span>
                  ): this.props.likes !== undefined ? (
                    <span className={`${liked ? 'FeedModalSidebarLikeButton--liked' : 'liked'}`}>
                      {/* <span className={cx('fa', 'fa-arrow-up', 'FeedModalSidebarLikeButton', {'FeedModalSidebarLikeButton--liked': liked})}></span> Likes ({likes}) */}
                      <span className={`fa fa-arrow-up FeedModalSidebarLikeButton${liked ? ' FeedModalSidebarLikeButton--liked' : ''}`}></span> Likes ({likes})
                    </span>
                  ): null
                }
                {
                  contextActions.showcase ? (
                    <a onClick={this._showcaseToggle} style={{marginLeft: 10}}>
                      <span className={cx('fa', 'fa-trophy', 'FeedModalSidebarLikeButton', {'FeedModalSidebarLikeButton--liked': showcased})}></span> Showcase
                    </a>
                  ): null
                }
                <a onClick={this._share} style={{marginLeft: 10}}>
                  <span className="fa fa-share-alt"></span> Share
                </a>
              </div>
            ): null
          }
        </div>
        {
          comments && (comments.commentable || comments.comments.length > 0) ? (
            <FeedModalSidebarCommentsArea
              cmd={cmd}
              comments={comments}
              target={this.props.activityUrl}
              />
          ): null
        }
        {sharing}
      </div>
    );
  }
}

export default FeedModalSidebar;
