import cx from 'classnames';
import React from 'react';
import ngDateFilter from '../../ngDateFilter';
import FeedHeader from './feed_parts/FeedHeader.jsx';
import FeedPostAvatar from './feed_parts/FeedPostAvatar.jsx';
import FeedPostBottomBar from './feed_parts/FeedPostBottomBar.jsx';
import FeedPostByline from './feed_parts/FeedPostByline.jsx';
import FeedPostCommentsArea from './feed_parts/FeedPostCommentsArea.jsx';
import FeedPostDropdown from './feed_parts/FeedPostDropdown.jsx';
import ArticleCard from './bits/ArticleCard.jsx';
import MvpCard from './bits/MvpCard.jsx';
import Portal from '../../misc/Portal';
import ScoreCard from './bits/ScoreCard.jsx';
import PhotoFeedModal from './feed_parts/modals/PhotoFeedModal.jsx';
import VideoFeedModal from './feed_parts/modals/VideoFeedModal.jsx';
import VideoPanel from './bits/VideoPanel.jsx';
import VideoVitalCard from './bits/VideoVitalCard.jsx';
import CombineVideoVitalCard from './bits/CombineVideoVitalCard.jsx';
// import './FeedItems.css';

const TIMESTAMP_FORMAT = 'MMM d, y h:mm a';
const WINDOW_URL = window.location.pathname;

export class FeedPostBasic extends React.PureComponent {
  render() {
    const {comments, post} = this.props;
    const formattedDate = ngDateFilter(post.created_at, TIMESTAMP_FORMAT);
    const shareHref = post._links.self.href;

    let commentsLength = false;
    if (comments) {
      commentsLength = comments.comments.length;
    }

    return (
      <article className="Post">
      <FeedHeader post={post} actionUrls={this.props.contextActions} cmd={this.props.cmd} />
        <div className="Post-content">
          <span className="box-c"></span>
          <p className="post-content text-preline" dangerouslySetInnerHTML={{__html: post.content_html}}></p>
        </div>
        <FeedPostBottomBar
          activityUrl={post._links.self.href}
          shareHref={shareHref}
          likes={this.props.likes}
          liked={this.props.liked}
          onLike={this.props.cmd.like}
          commentsLength={commentsLength}
        />
        {
          comments ? (
            <FeedPostCommentsArea post={post} cmd={this.props.cmd} comments={comments} target={post._links.self.href} />
          ): null
        }
      </article>
    );
  }
}

export class FeedPostArticle extends React.PureComponent {
  render() {
    const {comments, post} = this.props;
    const formattedDate = ngDateFilter(post.created_at, TIMESTAMP_FORMAT);
    const shareHref = post.activity._links.self.href;
    const nextLink = {
      href: post.activity._links.self.href,
      title: 'Article',
    };
    let description = 'posted an article';
    if (post.activity.article_type === 'recap') {
      nextLink.title = 'Recap';
      description = 'posted a Recap';
    } else if (post.activity.article_type === 'preview') {
      nextLink.title = 'Preview';
      description = 'posted a preview';
    }

    return (
      <article className="Post Recap PostArticle">
        <FeedHeader post={post} actionUrls={this.props.contextActions} cmd={this.props.cmd} description={description} />
        <div className="Post-content">
          <span className="box-c"></span>
          <ArticleCard article={post.activity} nextLink={nextLink} />
          <div className="text-center">
            <a href={nextLink.href} className="Post-pageLink">
              Go To { nextLink.title }
            </a>
          </div>
        </div>
        <FeedPostBottomBar activityUrl={post.activity._links.self.href} shareHref={shareHref} likes={this.props.likes} liked={this.props.liked} onLike={this.props.cmd.like} />
        {
          comments ? (
            <FeedPostCommentsArea post={post} cmd={this.props.cmd} comments={comments} target={post.activity._links.self.href} />
          ): null
        }
      </article>
    );
  }
}

export class FeedPostGameCancellation extends React.PureComponent {
  render() {
    let post = this.props.post;
    return (
      <div className="activity-wrapper PostGameCancellation">
        <div className="result">
          <p>
            <a href={post.activity._links.user.href}>
              {post.activity._links.user.title}
            </a>
            {' cancelled '}
            <a href={post.activity._links.game.href}>
              {post.activity._links.game.title}
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export class FeedPostGameChangeLog extends React.PureComponent {
  render() {
    const post = this.props.post;
    let description = null;
    let postContent = '';
    switch (post.activity.change_type) {
    case 'cancelled':
      description = (
        <>
          {' cancelled '}
          <a href={post.activity._links.game.href}>
            {post.activity._links.game.title}
          </a>
        </>
      );
      postContent = `${ngDateFilter(post.activity.change_data.datetime, TIMESTAMP_FORMAT)}`;
      break;
    case 'rescheduled':
      description = (
        <>
          {' rescheduled '}
          <a href={post.activity._links.game.href}>
            {post.activity._links.game.title}
          </a>
        </>
      );
      postContent = `${ngDateFilter(post.activity.change_data.new_datetime, TIMESTAMP_FORMAT)}`;
      break;
    }
    return (
      <div className="Post PostGameChangeLog">
        <FeedHeader post={post} actionUrls={this.props.contextActions} cmd={this.props.cmd} description={description} />
        <div className="result">
          <h2>{post.activity.change_type}</h2>
          <p>
            <a href={post.activity._links.game.href}>
              {post.activity._links.game.title}
            </a>
          </p>
          <p>
            { post.activity.change_type === 'rescheduled' ? 'to ' : '' }
            <span className={`${ post.activity.change_type === 'rescheduled' ? 'bold' : '' }${ post.activity.change_type === 'cancelled' ? 'italic' : '' }`}>{postContent}</span>
          </p>
        </div>
      </div>
    );
  }
}

export class FeedPostGameScoreEdit extends React.PureComponent {
  render() {
    const url = window.location.href
    let OnGamePage = url.includes('games')
    let post = this.props.post;
    let description = '';
    if (post.activity.final) {
      description = 'posted a final score';
    } else {
      description = 'posted a score update';
    }
    const shareHref = post._links.subject.href;

    return (
      <article className="Post GameScore">
        <FeedHeader post={post} actionUrls={this.props.contextActions} cmd={this.props.cmd} description={description} />
        <div className="Post-content">
          <span className="box-c"></span>
          <div className="post-content">
            <ScoreCard scoreEdit={post.activity} />
            <div className="text-center">
              {OnGamePage ? "" :
              <a href={post._links.subject.href} className="Post-pageLink">
                Go To Game Page
              </a>
              }
            </div>
          </div>
        </div>
        <FeedPostBottomBar shareHref={shareHref} shareExtra={post.activity} />
      </article>
    );
  }
}

export class FeedPostMvp extends React.PureComponent {
  render() {
    const {comments, post} = this.props;
    const shareHref = post.activity._links.self.href;
    const mvpType = post.activity._links.game ? "Game MVP" : "Event MVP";
    let description = `posted a ${mvpType}`;

    return (
      <div className="Post MVP">
        <FeedHeader post={post} actionUrls={this.props.contextActions} cmd={this.props.cmd} description={description} />
        <div className="Post-content">
          <span className="box-c"></span>
          <p><a href={post.activity._links.self.href}>{post.subject_display_name}</a></p>
          <div className="Flag">
            MVP
          </div>
          <MvpCard mvp={post.activity} />
          <FeedPostBottomBar
            activityUrl={post.activity._links.self.href}
            likes={this.props.likes}
            liked={this.props.liked}
            shareHref={shareHref}
            onLike={this.props.cmd.like}
          />
        </div>
        {
          comments ? (
            <FeedPostCommentsArea post={post} cmd={this.props.cmd} comments={comments} target={post.activity._links.self.href} />
          ): null
        }
      </div>
    );
  }
}

export class FeedPostPhoto extends React.PureComponent {
  _openModal = () => {
    const {picture, post} = this.props;
    this.props.onModalOpened(picture._links.self.href, post.id, picture._links.self.href);
  };

  render() {
    const {comments, creator, liked, likes, picture, post} = this.props;
    const formattedDate = ngDateFilter(post.created_at, TIMESTAMP_FORMAT);
    const shareHref = picture._links.self.href;
    let modal = null;

    let commentsLength = false;
    if (comments) {
      commentsLength = comments.comments.length;
    }

    if (this.props.modal && this.props.modal.key == picture._links.self.href && this.props.modal.postId == post.id) {
      modal = (
        <Portal>
          <PhotoFeedModal
            comments={comments}
            creator={creator}
            liked={liked}
            likes={likes}
            picture={picture}
            />
        </Portal>
      );
    }
    return (
      <article className="Post">
        <FeedHeader post={post} actionUrls={this.props.contextActions} cmd={this.props.cmd} />
        <div className="Post-content">
          <span className="box-c"></span>
          <p dangerouslySetInnerHTML={{__html: picture.description_html}}></p>
          <div className="Post-contentImg">
            <a onClick={this._openModal}>
              <img src={picture._links.v_large.href} alt={picture.caption} />
            </a>
          </div>
        </div>
          <FeedPostBottomBar
            activityUrl={picture._links.self.href}
            buyUrl={picture._links.buy && picture._links.buy.href}
            shareHref={shareHref}
            liked={liked}
            likes={likes}
            onLike={this.props.cmd.like}
            commentsLength={commentsLength}
          />
        {
          comments ? (
            <FeedPostCommentsArea post={post} cmd={this.props.cmd} comments={comments} target={picture._links.self.href} />
          ): null
        }
        {modal}
      </article>
    );
  }
}

export class FeedPostPhotoTag extends React.PureComponent {
  _openModal = () => {
    const {picture, post} = this.props;
    this.props.onModalOpened(picture._links.self.href, post.id, picture._links.self.href);
  };

  render() {
    const {comments, creator, liked, likes, picture, post} = this.props;
    const formattedDate = ngDateFilter(post.created_at, TIMESTAMP_FORMAT);
    const shareHref = picture._links.self.href;
    let destination = null;
    if (picture._links.destination) {
      destination = (
        <span>
          {' on '}
          <a href={picture._links.destination.href}>
            {picture._links.destination.title}
          </a>
        </span>
      );
    }
    let modal = null;
    if (this.props.modal && this.props.modal.key == picture._links.self.href && this.props.modal.postId == post.id) {
      modal = (
        <Portal>
          <PhotoFeedModal
            comments={comments}
            creator={creator}
            liked={liked}
            likes={likes}
            picture={picture}
            />
        </Portal>
      );
    }
    return (
      <div className="box-bottom-margin FeedPostPhotoTag clearfix">
        <FeedPostAvatar post={post} />

        <div className="box-l">
          <span className="box-c"></span>
          <div className="inner">
            <div className="post-header">
              <div className="toright poston">
                {formattedDate}
                {' '}
                <FeedPostDropdown post={post} actionUrls={this.props.contextActions} cmd={this.props.cmd} />
              </div>
              <div className="postby">
                <a href={post._links.actor.href}>{post.actor_display_name}</a>
                {' tagged '}
                <a href={post._links.subject.href}>{post.subject_display_name}</a>
                {' in a photo '}
                {destination}
              </div>
            </div>
            <div className="post-content" dangerouslySetInnerHTML={{__html: picture.description_html}}></div>
            <div className="post-content-edge-to-edge">
              <a className="posted-picture-wrapper" onClick={this._openModal}>
                <img className="posted-picture" src={picture._links.v_large.href} alt={picture.caption} />
              </a>
            </div>
          </div>
          <FeedPostBottomBar
            activityUrl={picture._links.self.href}
            buyUrl={picture._links.buy && picture._links.buy.href}
            shareHref={shareHref}
            liked={liked}
            likes={likes}
            onLike={this.props.cmd.like}
          />
        </div>
        {
          comments ? (
            <FeedPostCommentsArea post={post} cmd={this.props.cmd} comments={comments} target={picture._links.self.href} />
          ): null
        }
        {modal}
      </div>
    );
  }
}

export class FeedPostTeamCreated extends React.PureComponent {
  render() {
    const post = this.props.post;
    const formattedDate = ngDateFilter(post.created_at, TIMESTAMP_FORMAT);
    const verb = post.activity.band_id ? 'created a group' : 'created a team';
    return (
      <div className="Post FeedPostTeamCreated clearfix">
        <FeedHeader verb={verb} post={post} actionUrls={this.props.contextActions} cmd={this.props.cmd} />
        <div className="Post-content">
          <span className="box-c"></span>
          <div className="text-center pt-5 pb-3">
            <a href={post._links.subject.href}>
              <img src={post.activity._links.profile_picture.href} />
            </a>
            <div className="team-title">
              <a href={post._links.subject.href} className="title02">
                {post.activity.display_name}
              </a>
              <span className="Team-info">
                {
                  post.activity._links.band ? (
                    post.activity._links.band.title
                  ): (
                    post.activity.school_location
                  )
                }
              </span>
              <a href={post._links.subject.href} className="Post-pageLink mt-4">
                Team Page
              </a>
          </div>
          </div>
          <FeedPostBottomBar activityUrl={post._links.self.href} />
        </div>
      </div>
    );
  }
}

export class FeedPostTeamMemberJoined extends React.PureComponent {
  render() {
    const post = this.props.post;
    const formattedDate = ngDateFilter(post.created_at, TIMESTAMP_FORMAT);
    const verb = post.activity.user_id ? 'joined a team' : 'was added to the roster';
    return (
      <div className="Post FeedPostTeamMemberJoined">
        <FeedHeader post={post} verb={verb} actionUrls={this.props.contextActions} cmd={this.props.cmd} />
        <div className="Post-content">
          <span className="box-c"></span>
          <div className="text-center pt-5 pb-3">
            <a href={post._links.subject.href}>
              <img src={post.activity._embedded.team._links.profile_picture.href} />
            </a>
            <div className="team-title">
              <a href={post._links.subject.href} className="title02">
                {post.activity._embedded.team.display_name}
              </a>
              <span className="Team-info">{post.activity._embedded.team.school_location}</span>
              <a href={post._links.subject.href} className="Post-pageLink mt-4">
                Team Page
              </a>
            </div>
          </div>
          <FeedPostBottomBar activityUrl={post._links.self.href} />
        </div>
      </div>
    );
  }
}

export class FeedPostThankYouVideo extends React.PureComponent {
  _openModal = () => {
    const {post, video, videoLink} = this.props;
    if (videoLink) {
      this.props.onModalOpened(videoLink._links.self.href, post.id, videoLink._links.self.href);
    } else {
      this.props.onModalOpened(video._links.self.href, post.id, video._links.self.href);
    }
  };

  _played = () => {
    this.props.cmd.videoPlayed(this.props.video._links.self.href);
  };

  render() {
    const {comments, creator, liked, likes, post, video, videoLink} = this.props;
    const formattedDate = ngDateFilter(post.created_at, TIMESTAMP_FORMAT);
    const shareHref = videoLink ? videoLink._links.self.href : video._links.self.href;
    const thumbnail = videoLink ? videoLink.thumbnail : video._links.thumbnail.href;
    let modal = null;
    if (this.props.modal && this.props.modal.postId == post.id) {
      if (videoLink && this.props.modal.key == videoLink._links.self.href) {
        modal = (
          <Portal>
            <VideoFeedModal
              comments={comments}
              creator={creator}
              liked={liked}
              likes={likes}
              recipient={post.activity._links.recipient}
              video={videoLink}
              />
          </Portal>
        );
      } else if (video && this.props.modal.key == video._links.self.href) {
        modal = (
          <Portal>
            <VideoFeedModal
              comments={comments}
              creator={creator}
              liked={liked}
              likes={likes}
              recipient={post.activity._links.recipient}
              video={video}
              onPlay={this._played}
              />
          </Portal>
        );
      }
    }
    return (
      <div className="box-bottom-margin FeedPostThankYouVideo clearfix">
        <FeedPostAvatar post={post} />

        <div className="box-l">
          <span className="box-c"></span>
          <div className="inner">
            <div className="post-header">
              <div className="toright poston">
                {formattedDate}
                {' '}
                <FeedPostDropdown post={post} actionUrls={this.props.contextActions} cmd={this.props.cmd} />
              </div>
              <div className="postby">
                <a href={post._links.actor.href}>{post.actor_display_name}</a>
                {' posted a thank you video to '}
                <a href={post._links.subject.href}>{post.subject_display_name}</a>
              </div>
            </div>
            {
              videoLink ? (
                <a className="AspectRatioBox AspectRatioBox--ratio-16-9ish post-content-edge-to-edge" onClick={this._openModal}>
                  <div className="AspectRatioBoxViewport">
                    <img className="AspectRatioBoxViewport-img" src={thumbnail} />
                    <div className="AspectRatioBoxViewport-overlay2">
                      <i className="fa fa-play-circle-o video-link-post-overlay"></i>
                    </div>
                  </div>
                </a>
              ): (
                <VideoPanel video={video} onClick={video.ready_at ? this._openModal : this._refresh} />
              )
            }
          </div>
          {
            videoLink ? (
              <FeedPostBottomBar activityUrl={videoLink._links.self.href} shareHref={shareHref} liked={liked} likes={likes} onLike={this.props.cmd.like} />
            ): (
              <FeedPostBottomBar activityUrl={video._links.self.href} shareHref={shareHref} liked={liked} likes={likes} onLike={this.props.cmd.like} />
            )
          }
        </div>
        {
          comments ? (
            videoLink ? (
              <FeedPostCommentsArea post={post} cmd={this.props.cmd} comments={comments} target={videoLink._links.self.href} />
            ): (
              <FeedPostCommentsArea post={post} cmd={this.props.cmd} comments={comments} target={video._links.self.href} />
            )
          ): null
        }
        {modal}
      </div>
    );
  }
}

export class FeedPostVideo extends React.PureComponent {
  _openModal = () => {
    const {post, video} = this.props;
    this.props.onModalOpened(video._links.self.href, post.id, video._links.self.href);
  };

  _played = () => {
    this.props.cmd.videoPlayed(this.props.video._links.self.href);
  };

  _refresh = (event) => {
    window.location.reload();
  };

  render() {
    const {comments, creator, post, video, liked, likes} = this.props;
    const formattedDate = ngDateFilter(post.created_at, TIMESTAMP_FORMAT);
    const shareHref = video._links.self.href;
    const ready = !!video.ready_at;
    let descriptionHtml = video.description_html;
    if (video._links.destination && video._links.destination.title === 'Intro Videos') {
      if (descriptionHtml) {
        descriptionHtml = 'New Intro Video: ' + descriptionHtml;
      } else {
        descriptionHtml = 'New Intro Video';
      }
    }
    let modal = null;
    if (ready && this.props.modal && this.props.modal.key == video._links.self.href && this.props.modal.postId == post.id) {
      modal = (
        <Portal>
          <VideoFeedModal
            comments={comments}
            creator={creator}
            liked={liked}
            likes={likes}
            video={video}
            onPlay={this._played}
            />
        </Portal>
      );
    }

    let commentsLength = false;
    if (comments) {
      commentsLength = comments.comments.length;
    }

    return (
      <article className="Post video">
        <FeedHeader post={post} actionUrls={this.props.contextActions} cmd={this.props.cmd} />
        <div className="Post-content">
          <span className="box-c"></span>
          <div className="inner">
            <p className="post-content" dangerouslySetInnerHTML={{__html: descriptionHtml}}></p>
            <VideoPanel video={video} onClick={ready ? this._openModal : this._refresh} />
          </div>
        </div>
        {
          ready ? (
            <FeedPostBottomBar
              activityUrl={video._links.self.href}
              shareHref={shareHref}
              liked={liked}
              likes={likes}
              onLike={this.props.cmd.like}
              commentsLength={commentsLength}
          />
          ): null
        }
        {
          comments ? (
            <FeedPostCommentsArea post={post} cmd={this.props.cmd} comments={comments} target={video._links.self.href} />
          ): null
        }
        {modal}
      </article>
    );
  }
}

export class FeedPostVideoLink extends React.PureComponent {
  _openModal = () => {
    const {post, videoLink} = this.props;
    this.props.onModalOpened(videoLink._links.self.href, post.id, videoLink._links.self.href);
  };

  render() {
    const {comments, creator, liked, likes, post, videoLink} = this.props;
    const formattedDate = ngDateFilter(post.created_at, TIMESTAMP_FORMAT);
    const shareHref = videoLink._links.self.href;
    let descriptionHtml = videoLink.description_html;
    if (videoLink._links.destination && videoLink._links.destination.title === 'Intro Videos') {
      if (descriptionHtml) {
        descriptionHtml = 'New Intro Video: ' + descriptionHtml;
      } else {
        descriptionHtml = 'New Intro Video';
      }
    }
    let modal = null;
    if (this.props.modal && this.props.modal.key == videoLink._links.self.href && this.props.modal.postId == post.id) {
      modal = (
        <Portal>
          <VideoFeedModal
            comments={comments}
            creator={creator}
            liked={liked}
            likes={likes}
            video={videoLink}
            />
        </Portal>
      );
    }
    return (
      <div className="Post FeedPostVideoLink">
      <FeedHeader post={post} actionUrls={this.props.contextActions} cmd={this.props.cmd} />
        <div className="Post-content">
          <span className="box-c"></span>
          <div className="Post-main">
            <p className="Post-description" style={{whiteSpace:"break-spaces"}} dangerouslySetInnerHTML={{__html: descriptionHtml}}></p>
            <a className="Post-contentVideo u-videoThumbnail AspectRatioBox--ratio-4-3" onClick={this._openModal}>
              <img className="AspectRatioBoxViewport-img" src={videoLink.thumbnail} />
            </a>
          </div>
          <FeedPostBottomBar activityUrl={videoLink._links.self.href} shareHref={shareHref} liked={liked} likes={likes} onLike={this.props.cmd.like} />
        </div>
        {
          comments ? (
            <FeedPostCommentsArea post={post} cmd={this.props.cmd} comments={comments} target={videoLink._links.self.href} />
          ): null
        }
        {modal}
      </div>
    );
  }
}

export class FeedPostVideoLinkTag extends React.PureComponent {
  _openModal = () => {
    const {post, videoLink} = this.props;
    this.props.onModalOpened(videoLink._links.self.href, post.id, videoLink._links.self.href);
  };

  render() {
    const {comments, creator, liked, likes, post, videoLink} = this.props;
    const formattedDate = ngDateFilter(post.created_at, TIMESTAMP_FORMAT);
    const shareHref = videoLink._links.self.href;
    let destination = null;
    if (videoLink._links.destination) {
      destination = (
        <span>
          {' on '}
          <a href={videoLink._links.destination.href}>
            {videoLink._links.destination.title}
          </a>
        </span>
      );
    }
    let modal = null;
    if (this.props.modal && this.props.modal.key == videoLink._links.self.href && this.props.modal.postId == post.id) {
      modal = (
        <Portal>
          <VideoFeedModal
            comments={comments}
            creator={creator}
            liked={liked}
            likes={likes}
            video={videoLink}
            />
        </Portal>
      );
    }
    return (
      <div className="box-bottom-margin FeedPostVideoLinkTag clearfix">
        <FeedPostAvatar post={post} />

        <div className="box-l">
          <span className="box-c"></span>
          <div className="inner">
            <div className="post-header">
              <div className="toright poston">
                {formattedDate}
                {' '}
                <FeedPostDropdown post={post} actionUrls={this.props.contextActions} cmd={this.props.cmd} />
              </div>
              <div className="postby">
                <a href={post._links.actor.href}>{post.actor_display_name}</a>
                {' tagged '}
                <a href={post._links.subject.href}>{post.subject_display_name}</a>
                {' in a video '}
                {destination}
              </div>
            </div>
            <div className="post-content" dangerouslySetInnerHTML={{__html: videoLink.description_html}}></div>
            <a className="AspectRatioBox AspectRatioBox--ratio-16-9ish post-content-edge-to-edge" onClick={this._openModal}>
              <div className="AspectRatioBoxViewport">
                <img className="AspectRatioBoxViewport-img" src={videoLink.thumbnail} />
                <div className="AspectRatioBoxViewport-overlay2">
                  <i className="fa fa-play-circle-o video-link-post-overlay"></i>
                </div>
              </div>
            </a>
          </div>
          <FeedPostBottomBar activityUrl={videoLink._links.self.href} shareHref={shareHref} liked={liked} likes={likes} onLike={this.props.cmd.like} />
        </div>
        {
          comments ? (
            <FeedPostCommentsArea post={post} cmd={this.props.cmd} comments={comments} target={videoLink._links.self.href} />
          ): null
        }
        {modal}
      </div>
    );
  }
}

export class FeedPostVideoTag extends React.PureComponent {
  _openModal = () => {
    const {post, video} = this.props;
    this.props.onModalOpened(video._links.self.href, post.id, video._links.self.href);
  };

  _played = () => {
    this.props.cmd.videoPlayed(this.props.video._links.self.href);
  };

  _refresh = (event) => {
    window.location.reload();
  };

  render() {
    const {comments, creator, post, video, liked, likes} = this.props;
    const formattedDate = ngDateFilter(post.created_at, TIMESTAMP_FORMAT);
    const shareHref = video._links.self.href;
    let destination = null;
    if (video._links.destination) {
      destination = (
        <span>
          {' on '}
          <a href={video._links.destination.href}>
            {video._links.destination.title}
          </a>
        </span>
      );
    }
    const ready = !!video.ready_at;
    let modal = null;
    if (ready && this.props.modal && this.props.modal.key == video._links.self.href && this.props.modal.postId == post.id) {
      modal = (
        <Portal>
          <VideoFeedModal
            comments={comments}
            creator={creator}
            liked={liked}
            likes={likes}
            video={video}
            onPlay={this._played}
            />
        </Portal>
      );
    }
    return (
      <div className="box-bottom-margin FeedPostVideoTag clearfix">
        <FeedPostAvatar post={post} />
        <div className="box-l">
          <span className="box-c"></span>
          <div className="inner">
            <div className="post-header">
              <div className="toright poston">
                {formattedDate}
                {' '}
                <FeedPostDropdown post={post} actionUrls={this.props.contextActions} cmd={this.props.cmd} />
              </div>
              <div className="postby">
                <a href={post._links.actor.href}>{post.actor_display_name}</a>
                {' tagged '}
                <a href={post._links.subject.href}>{post.subject_display_name}</a>
                {' in a video '}
                {destination}
              </div>
            </div>
            <div className="post-content" dangerouslySetInnerHTML={{__html: video.description_html}}></div>
            <VideoPanel video={video} onClick={ready ? this._openModal : this._refresh} />
          </div>
          {
            ready ? (
              <FeedPostBottomBar activityUrl={video._links.self.href} shareHref={shareHref} liked={liked} likes={likes} onLike={this.props.cmd.like} />
            ): null
          }
          {
            comments ? (
              <FeedPostCommentsArea post={post} cmd={this.props.cmd} comments={comments} target={video._links.self.href} />
            ): null
          }
          {modal}
        </div>
      </div>
    );
  }
}

export class FeedPostVideoVital extends React.PureComponent {
  _openModal = () => {
    const {post, video, videoLink} = this.props;
    if (videoLink) {
      this.props.onModalOpened(videoLink._links.self.href, post.id, videoLink._links.self.href);
    } else {
      this.props.onModalOpened(video._links.self.href, post.id, video._links.self.href);
    }
  };

  _played = () => {
    this.props.cmd.videoPlayed(this.props.video._links.self.href);
  };

  render() {
    const {comments, creator, liked, likes, post, video, videoLink, videoVital} = this.props;
    const formattedDate = ngDateFilter(post.created_at, TIMESTAMP_FORMAT);
    const shareHref = videoLink ? videoLink._links.self.href : video._links.self.href;
    let modal = null;
    if (this.props.modal && this.props.modal.postId == post.id) {
      if (videoLink && this.props.modal.key == videoLink._links.self.href) {
        modal = (
          <Portal>
            <VideoFeedModal
              comments={comments}
              creator={creator}
              liked={liked}
              likes={likes}
              recipient={post.activity._links.recipient}
              video={videoLink}
              />
          </Portal>
        );
      } else if (video && this.props.modal.key == video._links.self.href) {
        modal = (
          <Portal>
            <VideoFeedModal
              comments={comments}
              creator={creator}
              liked={liked}
              likes={likes}
              recipient={post.activity._links.recipient}
              video={video}
              onPlay={this._played}
              />
          </Portal>
        );
      }
    }
    const verb = videoVital._links.combine ? 'posted a Combine Video Vital' : 'posted a Video Vital';
    let videoArea = null;
    if (videoVital._links.combine) {
      videoArea = (
        <CombineVideoVitalCard video={video} videoLink={videoLink} videoVital={videoVital} onOpenModal={this._openModal} />
      );
    } else {
      videoArea = (
        <VideoVitalCard video={video} videoLink={videoLink} videoVital={videoVital} onOpenModal={this._openModal} />
      );
    }
    return (
      <div className="Post FeedPostVideoVital">
      <FeedHeader post={post} verb={verb} actionUrls={this.props.contextActions} cmd={this.props.cmd} />

        <div className="Post-content">
          <span className="box-c"></span>
          <div className="inner">
            {videoArea}
          </div>
          {
            videoLink ? (
              <FeedPostBottomBar activityUrl={videoLink._links.self.href} shareHref={shareHref} liked={liked} likes={likes} onLike={this.props.cmd.like} />
            ): (
              <FeedPostBottomBar activityUrl={video._links.self.href} shareHref={shareHref} liked={liked} likes={likes} onLike={this.props.cmd.like} />
            )
          }
        </div>
        {
          comments ? (
            videoLink ? (
              <FeedPostCommentsArea post={post} cmd={this.props.cmd} comments={comments} target={videoLink._links.self.href} />
            ): (
              <FeedPostCommentsArea post={post} cmd={this.props.cmd} comments={comments} target={video._links.self.href} />
            )
          ): null
        }
        {modal}
      </div>
    );
  }
}
